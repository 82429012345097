import useAsyncEffect from 'use-async-effect';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { awaitCondition } from '@/utils/sync';
import { Spinner } from '@/Spinner';
import { Flexbox } from '@/components/flex';

export interface HubspotFormProps {
  formId: string;
  portalId: string;
  additionalCss?: string;
  submitText?: string;

  onFormReady?: (formElem: HTMLFormElement) => void;
}

const formCss = `
  input[type="text"], input[type="email"], textarea {
    border: 1px solid #AFB7C8!important;
    border-radius: 8px!important;
    background-color: #FFFFFF!important;
    padding: 22px 20px!important;
    font-size: 14px!important;
  }

  label[for] {
    display: none!important;
  }

  input[type="submit"] {
    padding: 20px!important;
    font-size: 18px!important;
    font-weight: normal!important;
    color: white!important;
    width: 100%!important;
    background-color: #DC4949!important;
    border-radius: 30px!important;
  }

  select {
    border-radius: 8px!important;
    width: 100%!important;
    height: 46px!important;
  }
`;

export function HubspotForm({
  formId,
  portalId,
  additionalCss,
  submitText,
  onFormReady,
}: HubspotFormProps) {
  const targetId: string = useMemo(
    () => `hubspot-form-${portalId}-${formId}`,
    [formId, portalId],
  );

  const [isVisible, setIsVisible] = React.useState(false);

  useAsyncEffect(async () => {
    try {
      // @ts-ignore
      await awaitCondition(() => window?.hbspt?.forms?.create, 500, 8);

      // @ts-ignore
      window.hbspt.forms.create({
        target: `#${targetId}`,
        region: `eu1`,
        portalId,
        formId,
        onFormReady,
      });

      await awaitCondition(
        () => !!document.querySelector(`#${targetId} iframe`),
        500,
        10,
      );

      const styles = document.createElement(`style`);
      styles.innerHTML = `${formCss}
${additionalCss || ``}`;
      document
        .querySelector(`#${targetId} iframe`)
        // @ts-ignore
        .contentDocument.head.appendChild(styles);

      await sleep(2000);

      const frameDoc = document.querySelector(
        `#${targetId} iframe`,
        // @ts-ignore
      )?.contentDocument as Document;

      frameDoc.querySelectorAll(`.field`)?.forEach((field) => {
        const placeholder = field?.querySelector(`label`)?.innerText;
        field.querySelector(`input`)?.setAttribute(`placeholder`, placeholder);
      });

      frameDoc.querySelectorAll(`.hs-form-field`)?.forEach((field) => {
        const placeholder = field?.querySelector(`label`)?.innerText;
        field
          .querySelector(`textarea`)
          ?.setAttribute(`placeholder`, placeholder);
      });

      frameDoc
        ?.querySelector(`input[type="submit"]`)
        ?.setAttribute(`value`, submitText || `Submit`);

      setIsVisible(true);
    } catch (ex) {
      console.error(`vsly`, ex);
    }
  }, []);

  return (
    <>
      <Wrapper
        style={{ display: isVisible ? `block` : `none` }}
        id={targetId}
        className="hubspot-form"
      />
      {!isVisible && (
        <Flexbox justify="center" align="center">
          <Spinner className="hubspot-spinner" size={30} />
        </Flexbox>
      )}
    </>
  );
}

const Wrapper = styled.div`
  min-height: 30rem;
  iframe {
    min-height: 350px !important;
  }
`;

const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
